/* @import "react-calendar/dist/Calendar.css"; */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background: #111;
    color: white;
  }

  .react-calendar {
    background: #222 !important;
    /* border: 1px solid #444 !important; */
    @apply mx-auto  rounded-md border-2 border-gray-600;
  }
}
/* .react-calendar__month-view__days__day--weekend {
  color: rgb(255, 72, 16);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgb(54, 54, 54) !important;
  border-radius: 10px;
}
.react-calendar__tile {
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-left: 4px !important;
  margin-right: 4px !important;
} */
/**
 * Loader icon
 */
.loader-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
input,
textarea {
  user-select: text;
  -webkit-user-select: text;
}
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  /* margin: 1em auto; */
  text-align: left;
}

.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  /* background: #333; */
  color: #fff;
}

.geosuggest__input:focus {
  border-color: #ccc;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #333;
  border: 2px solid #ccc;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
  * A geosuggest item
  */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #333;
}

.geosuggest__item--active {
  background: #333;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
